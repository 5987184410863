const BaseUrl = 'https://indirectprocurementplan-qa.basf.com/api';
export const environment = {
  production: false,
  //authorization for user
  RetrieveAllRolesByUserId: BaseUrl + '/Users/RetrieveAllRolesByUserId',

  //user service
  MenuDetailsByUserId: BaseUrl + '/Users/MenuDetailsByUserId',
  RetrieveProcurementSectionDetails:
    BaseUrl + '/Procurement/RetrieveProcurementSectionDetails',
  RetrievePackageDetailsSection:
    BaseUrl + '/Package/RetrievePackageDetailsSection',

  getPackageDiscplineDetails: BaseUrl + '/Package/RetrivePackageDiscipline',
  getMasterDataDetails: BaseUrl + '/Package/RetriveMasterdataDetails',
  savePackageDetails: BaseUrl + '/Package/insertpackagedetails',
  postScheduler: BaseUrl + `/Scheduler/Schedulers`,
  getMaterialGroup: BaseUrl + '/Package/RetrieveCategoryNamebyId',

  sendEmailforMoreInformation:
    BaseUrl + '/procurement/sendEmailforMoreInformation',
  //approval-strategy
  CreateApprovers: BaseUrl + '/Approver/Approvers',
  GetApprovers: BaseUrl + '/Approver/RetrieveApprovers',
  ApproveOrRejectProcurement: BaseUrl + '/Approver/ApproveOrRejectProcurement',
  ApproverorRejectMultipleRecords:
    BaseUrl + '/Approver/ApproverorRejectMultipleRecords',
  getallschedulers: BaseUrl + `/Scheduler/RetrieveAllSchedulers`,
  getallusers: BaseUrl + '/Users/RetrieveAllUsers',
  retrieveUserbyLoginName:
    BaseUrl + `/Admin/Users/{loginId}/loggedInUser/{userName}`,

  GetSchedulersByPackageId:
    BaseUrl + `/Scheduler/RetrieveSchedulersByPackageId`,

  getPackageDetails: BaseUrl + `/Package/RetrievePackageDetails`,
  getUsers: BaseUrl + `/Users/RetrieveAllUsers`,
  postUser: BaseUrl + `/Users/UserDetails`,
  getUserByEmailId: BaseUrl + `/Users/RetrieveUserByEmailId`,

  getPackageInfo: BaseUrl + `/Package/RetrievePackageDetailByPackageId`,
  getPackageByPlanId: BaseUrl + '/Package/RetrievePackageDetailsByPlanId',
  getProcurementDetails:
    BaseUrl + '/Procurement/ReteriveProcurementPlanDetailById',
  GetApprovelHistrory: BaseUrl + `/Approver/RetrieveApprovelHistrory`,
  sendPackageStatusChange: BaseUrl + `/procurement/PackageStatusChange`,

  updateInaccurateComments: BaseUrl + '/Procurement/Inaccuratecomments',

  //category-buyer
  RetriveMasterdataDetails: BaseUrl + '/CategoryBuyer/RetriveMasterdataDetails',
  RetrievePackageDetailsbyId:
    BaseUrl + '/CategoryBuyer/RetrievePackageDetailsbyId',
  insertcategorybuyerinfodetails:
    BaseUrl + '/CategoryBuyer/insertcategorybuyerinfodetails',

  //package
  RetriveMasterdataDetailsPackage:
    BaseUrl + '/Package/RetriveMasterdataDetails',
  SendEmailtoApprovers: BaseUrl + '/Package/SendEmailtoApprovers',
  //procurement
  RetrivePlantCodeDetails: BaseUrl + '/Procurement/RetrivePlantCodeDetails',
  UpdatePlanstatus: BaseUrl + '/Procurement/UpdatePlanstatus',
  //New Procurement
  insertProcurementDetails: BaseUrl + '/Procurement/insertprocurementdetails',
  //Tags
  inserttagdetails: BaseUrl + '/Tag/inserttagdetails',
  RetriveRisqdataDetails: BaseUrl + '/Tag/RetriveRisqdataDetails',
  Uploadtagdetails: BaseUrl + '/Tag/Uploadtagdetails',
  RetrieveTagDetails: BaseUrl + '/Tag/RetrieveTagDetails',
  //category buyer
  RetrieveCategorypackageDetailsById:
    BaseUrl + '/CategoryBuyer/RetrievePackageDetailsbyId',
  GetComments: `${BaseUrl}/Package/RetrieveResponse`,
  CreateComments: `${BaseUrl}/Package/InsertRsponse`,
  PlanGetComments: `${BaseUrl}/Procurement/ReteriveInaccurateCommetnsByPlainId`,
  PlanCreateComments: `${BaseUrl}/Procurement/ResponseToInaccurateComments`,
  getProcurementGridDetails:
    BaseUrl + `/Procurement/RetrieveProcurementPlanDetails`,
  //change log
  GetPackageChangeLogByPackageId: `${BaseUrl}/Package/GetPackageChangeLogByPackageId`,

  getPurchaseDetails: `${BaseUrl}/Purchase/GetPurchaseDetails`,
  insertPurchaseNumber: `${BaseUrl}/Purchase/InsertPurchase`,
  updatePurchaseNumber: `${BaseUrl}/Purchase/updatePurchase`,
  getPurchaseNumberByPackage: `${BaseUrl}/Purchase/GetPurchases`,

  SavePurchasingGroup: `${BaseUrl}/Admin/PurchasingGroup`,
  RetrievePurchasingGroup: `${BaseUrl}/Admin/PurchasingGroup/{purchasingGroupId}/User/{loginName}`,

  RetrieveCategoryCollection: `${BaseUrl}/Admin/Category/List/User/{loginName}`,
  RetrieveCategory: `${BaseUrl}/Admin/Category/{categoryId}/User/{loginName}`,
  SaveCategory: `${BaseUrl}/Admin/Category`,
  RetrieveCategoryMaster: `${BaseUrl}/Admin/Category/Master/User/{loginName}`,
  //Login
  decryptUserDetails: `${BaseUrl}/Users/decryptUserdetails`,
  retrieveJwt: `${BaseUrl}/Jwt/GetToken`,
  RetrieveCategoryMasterForPurchasingGroup: `${BaseUrl}/Admin/Category/Master/PurchasingGroup/{purchasingGroupId}/User/{loginName}`,

  retrieveJWTToken: `${BaseUrl}/Jwt/Token`,
  GetFopComments: `${BaseUrl}/CustomerFop/GetFopComments`,

  //contract requirements
  RetriveContactdataDetails: `${BaseUrl}/Contract/RetriveContactdataDetails`,
  InsertContactdataDetails: `${BaseUrl}/Contract/InsertContactdataDetails`,
  //Plant code
  RetrivePlantCodeDetailsForAdmin: `${BaseUrl}/PlantCode/RetrivePlantCodeDetails`,
  InsertPlantCodeDetails: `${BaseUrl}/PlantCode/InsertPlantCodeDetails`,
  EnablePlantCodeDetails: `${BaseUrl}/PlantCode/EnablePlantCodeDetails`,
  getSamlURL: `${BaseUrl}/Saml/GetSamlURL`
  
};
